import { useEffect } from 'react'

export function useKeyboardShortcut({ keyCombo, onKeyPressed }) {
  useEffect(() => {
    function keyDownHandler(e: globalThis.KeyboardEvent) {
      const { key, metaKey, ctrlKey, shiftKey, altKey } = e

      // Parse the key combination
      const comboParts = keyCombo.toLowerCase().split('+')
      const isMetaRequired = comboParts.includes('meta')
      const isCtrlRequired = comboParts.includes('ctrl')
      const isShiftRequired = comboParts.includes('shift')
      const isAltRequired = comboParts.includes('alt')
      const mainKey = comboParts.find((part) => !['meta', 'ctrl', 'shift', 'alt'].includes(part))

      // Check if the combination matches
      const isComboMatched =
        (isMetaRequired ? metaKey : true) &&
        (isCtrlRequired ? ctrlKey : true) &&
        (isShiftRequired ? shiftKey : true) &&
        (isAltRequired ? altKey : true) &&
        key.toLowerCase() === mainKey

      if (isComboMatched) {
        e.preventDefault()
        onKeyPressed()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [keyCombo, onKeyPressed])
}
