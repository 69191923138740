import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useHistory, useLocation } from 'react-router-dom'
import { message } from 'antd'

import { formatCampaignErrors } from './helpers'
import {
  createCampaign,
  updateCampaign,
  createCampaignZaloGroup,
  createContent,
  addCampaignContent,
  addCampaignSchedule,
  publishCampaign,
} from 'Services/campaigns'
import Header from './Components/Header'
import Body from './Components/Body'
import ProgressBar from './Components/ProgressBar'
import { addTagToCampaign } from 'Services/campaign-tags'

export default function NewCampaign() {
  const setCampaignState = useStoreActions((action: any) => action.campaignModule.setState)
  const search = useLocation().search
  const id = new URLSearchParams(search).get('id')
  const history = useHistory()
  const {
    name,
    description,
    selected_placement,
    content,
    mode,
    start_time,
    start_date,
    end_time,
    end_date,
    days,
    start_at,
    audience_id,
    daily_budget,
    bid_amount,
    kpi,
    campaign_tag_ids,
    zalo_groups,
    dynamic_param_dimensions,
  } = useStoreState((state: any) => state.campaignModule)

  const { setState } = useStoreActions((actions: any) => actions.campaignModule)
  const { setBreadCrumb } = useStoreActions((actions: any) => actions.breadcrumb)
  const [errors, setErrors] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [disableCreatePublish, setDisableCreatePublish] = useState(false)
  const [action, setAction] = useState('save')

  const onCreateCampaign = async () => {
    try {
      const campaignResp = await createCampaign({
        name,
        description,
        daily_budget,
        bid_amount,
        kpi,
      })

      let dynamic_param_dimensions_payload

      // First, check if dynamic_param_dimensions is already an object, and if not, parse it
      if (typeof dynamic_param_dimensions === 'string') {
        dynamic_param_dimensions_payload = JSON.parse(dynamic_param_dimensions)
      } else {
        dynamic_param_dimensions_payload = dynamic_param_dimensions
      }

      // Ensure it's an array
      dynamic_param_dimensions_payload = Array.isArray(dynamic_param_dimensions_payload)
        ? dynamic_param_dimensions_payload
        : [dynamic_param_dimensions_payload]

      // Iterate through each element and assign segmentation_type if it is null or undefined
      dynamic_param_dimensions_payload = dynamic_param_dimensions_payload.map((item) => {
        if (item && typeof item === 'object' && 'segmentation_type' in item) {
          if (item.segmentation_type === null) {
            return {
              ...item,
              segmentation_type: 'customer',
            }
          }
          return item
        } else {
          return {
            ...item,
            segmentation_type: 'customer', // Default value
          }
        }
      })

      // Filter out objects that only have the segmentation_type field
      dynamic_param_dimensions_payload = dynamic_param_dimensions_payload.filter((item) => {
        return Object.keys(item).length > 1 || item.segmentation_type !== 'customer'
      })

      const contentResp = await createContent({
        content,
        placement_id: selected_placement.id,
        ...(dynamic_param_dimensions_payload.length > 0 &&
        dynamic_param_dimensions_payload[0].segmentation_type !== null
          ? { dynamic_param_dimensions: JSON.stringify(dynamic_param_dimensions_payload) }
          : ({} as any)),
      })

      if (selected_placement.code === 'zalo_group') {
        await createCampaignZaloGroup({ campaign_id: campaignResp.data.id, zalo_groups })
      } else {
        await updateCampaign({
          campaign_id: campaignResp.data.id,
          audience_segment_id: audience_id,
        })
      }
      await addCampaignContent({
        campaign_id: campaignResp.data.id,
        content_id: contentResp.data.id,
      })
      await addCampaignSchedule({
        campaign_id: campaignResp.data.id,
        schedule: {
          mode,
          published: false,
          start_time: start_date ? `${start_date} ${start_time}:00` : '',
          end_time: end_date ? `${end_date} ${end_time}:00` : '',
          days,
          start_at: start_at ? `${start_at}:00` : '',
        },
      })
      return { id: campaignResp.data.id }
    } catch (err: any) {
      console.log('err', err)
      throw err
    }
  }

  const handleCreateCampaign = async () => {
    sessionStorage.removeItem('dimension')
    setIsSubmitting(true)
    try {
      await onCreateCampaign()
      message.success('Created campaign successfully')
      history.push(`/campaigns`)
    } catch (err: any) {
      setErrors(formatCampaignErrors(err?.response?.data))
    } finally {
      setIsSubmitting(false)
    }
  }

  const handlePublishCampaign = async () => {
    setAction('publish')
    setDisableCreatePublish(true)
    try {
      const resp = await onCreateCampaign()
      await publishCampaign({ campaign_id: resp?.id, audience_id })
      //await addTagToCampaign({ campaign_id: resp?.id, tag_ids: campaign_tag_ids })
      setAction('save')
      message.success('Published campaign successfully')
      history.push(`/campaigns`)
    } catch (err: any) {
      setErrors(formatCampaignErrors(err?.response?.data))
      setDisableCreatePublish(false)
    }
  }
  useEffect(() => {
    setCampaignState({ key: 'audience_id', value: id })
  }, [])
  useEffect(() => {
    setBreadCrumb([
      { title: 'Campaign List', path: '/campaigns' },
      { title: 'New Campaign', path: '/campaigns/new' },
    ])
  }, [setBreadCrumb])

  return (
    <div>
      <Header
        disablePublish={disableCreatePublish}
        handleCreateCampaign={handleCreateCampaign}
        handlePublishCampaign={handlePublishCampaign}
        errors={errors}
        isSubmitting={isSubmitting}
      />
      <div className="py-4 px-7 bg-white">
        <ProgressBar />
      </div>

      <div>
        <Body />
      </div>
    </div>
  )
}
