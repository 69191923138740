import { Checkbox, Input, Tree } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import useZaloGroup from './useZaloGroup'
import React from 'react'

export default function ZaloGroup() {
  const { searchQuery, setSearchQuery, groups, onChangeZaloGroups, values } = useZaloGroup()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div
        style={{
          width: '500px',
          background: 'white',
          maxHeight: '400px',
          overflow: 'scroll',
          padding: '12px',
          paddingBottom: '0',
          borderRadius: '4px',
        }}
      >
        <div
          style={{
            fontSize: '14px',
            lineHeight: '22px',
            fontWeight: 600,
            marginBottom: '10px',
            color: 'rgba(0,0,0,0.6)',
          }}
        >
          Select store / group
        </div>
        <Input
          style={{ width: '100%', height: '40px' }}
          disabled={false}
          prefix={<SearchOutlined />}
          placeholder="Search table..."
          className="search-bar"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value)
          }}
        />
        <div style={{ marginTop: '16px' }}></div>
        <Tree
          treeData={groups}
          checkable
          checkedKeys={values}
          key={'store_code'}
          onCheck={(keys, nodes) => {
            const checkedKeys = (keys as string[])?.filter((key) => key.includes('kingfoodmart_'))
            if (searchQuery) {
              onChangeZaloGroups([...(values || []), ...(checkedKeys as string[])])
              return
            }
            onChangeZaloGroups(checkedKeys as string[])
          }}
        />

        {/* <Checkbox.Group
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 'fit-content',
          }}
          value={values}
        >
          {groups.map((group) => (
            <Checkbox
              onChange={(e) => {
                if ((values || [])?.includes(e.target.value)) {
                  onChangeZaloGroups(values?.filter((item) => item !== e.target.value))
                  return
                }

                onChangeZaloGroups([...(values || []), e.target.value])
              }}
              style={{
                paddingBottom: '16px',
                marginLeft: 0,
              }}
              key={group.code_name}
              value={group.code_name}
            >
              {group.name}
            </Checkbox>
          ))}
        </Checkbox.Group> */}
      </div>
    </div>
  )
}
