export const NEW_TEMPLATE_MESSAGE_PARAMETER = [
  {
    group_name: 'Customer 360',
    group_code: 'customer_360',
    parameters: [
      {
        parameter_code: 'name',
        format: 'string',
      },
      {
        parameter_code: 'birthday',
        format: 'date',
      },
      {
        parameter_code: 'last_order_date',
        format: 'date',
      },
      {
        parameter_code: 'last_category',
        format: 'string',
      },
      {
        parameter_code: 'last_city',
        format: 'string',
      },
      {
        parameter_code: 'ltv',
        format: 'none',
      },
      {
        parameter_code: 'cluster',
        format: 'string',
      },
      {
        parameter_code: 'membership',
        format: 'string',
      },
    ],
  },
  {
    group_name: 'Customer 360 2',
    group_code: 'customer_360_2',
    parameters: [
      {
        parameter_code: 'name2',
        format: 'string',
      },
      {
        parameter_code: 'birthday2',
        format: 'date',
      },
    ],
  },
  {
    group_name: 'Customer 360 32',
    group_code: 'customer_360_32',
    group_status: 'active',
    group_condition: JSON.stringify({
      type: 'AGE',
      operator: 'EQUAL',
      value: '32',
      time_value: '',
      count: '',
    }),
    parameters: [
      {
        parameter_code: 'name2',
        format: 'string',
      },
      {
        parameter_code: 'birthday2',
        format: 'date',
      },
    ],
  },
]

export const MESSAGE_PARAMETER_FORMAT = {
  string: [
    {
      format_key: 'lowercase',
      format_value: 'lowercase',
    },
    {
      format_key: 'capitalize_first',
      format_value: 'Capitalize first',
    },
    {
      format_key: 'capitalize_all_first',
      format_value: 'Capitalize All First',
    },
    {
      format_key: 'uppercase',
      format_value: 'UPPERCASE',
    },
    {
      format_key: '',
      format_value: 'None',
    },
  ],
  price: [
    {
      format_key: 'pretty_money',
      format_value: 'Pretty Money(10000 --> 10.000đ)',
    },
    {
      format_key: 'Short Money',
      format_value: 'Short Money(10000 --> 10 Nghìn)',
    },
    {
      format_key: 'supper_short_money',
      format_value: 'Supper Short Money(10000 --> 10k)',
    },
    {
      format_key: '',
      format_value: 'None',
    },
  ],
  date: [
    {
      format_key: 'days_ago',
      format_value: 'Days Ago',
    },
    {
      format_key: 'weeks_ago',
      format_value: 'Weeks Ago',
    },
    {
      format_key: 'months_ago',
      format_value: 'Months Ago',
    },
    {
      format_key: '',
      format_value: 'None',
    },
  ],
  number: [
    {
      format_key: 'pretty_int',
      format_value: 'Pretty Int(10000 --> 10.000)',
    },
    {
      format_key: '',
      format_value: 'None',
    },
  ],
}

export const addTextAtPosition = (text, position, newText) => {
  if (!text) return newText
  return text?.slice(0, position) + newText + text?.slice(position)
}

export const SEGMENTS = [
  {
    name: 'Age',
    code: 'AGE',
    description: '',
    field: 'number',
    operator: [
      {
        type: 'OPERATOR_SELECTION',
        name: 'is',
        code: 'EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is not',
        code: 'NOT_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is greater than',
        code: 'GREATER_THAN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is less than',
        code: 'LESS_THAN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is equal or greater than',
        code: 'GREATER_THAN_OR_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is less than or equal',
        code: 'LESS_THAN_OR_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is between',
        code: 'IS_BETWEEN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is not between',
        code: 'IS_NOT_BETWEEN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'in the following',
        code: 'IN_THE_FOLLOWING',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'not in the following',
        code: 'NOT_IN_THE_FOLLOWING',
      },
    ],
    values: [],
    time_operator: [],
  },
  {
    name: 'Gender',
    code: 'GENDER',
    description: '',
    field: 'number',
    operator: [
      {
        type: 'OPERATOR_SELECTION',
        name: 'is',
        code: 'EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is not',
        code: 'NOT_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'contains',
        code: 'CONTAINS',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'does not contain',
        code: 'DOES_NOT_CONTAIN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'start with',
        code: 'STARTS_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'does not start with',
        code: 'DOES_NOT_START_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'end with',
        code: 'ENDS_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'does not end with',
        code: 'DOES_NOT_END_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'in the following',
        code: 'IN_THE_FOLLOWING',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'not in the following',
        code: 'NOT_IN_THE_FOLLOWING',
      },
    ],
    values: [
      {
        name: 'Nam',
        code: '1',
      },
      {
        name: 'Nữ',
        code: '0',
      },
    ],
    time_operator: [],
  },
  {
    name: 'LTV',
    code: 'LTV',
    description: '',
    field: 'number',
    operator: [
      {
        type: 'OPERATOR_SELECTION',
        name: 'is',
        code: 'EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is not',
        code: 'NOT_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is greater than',
        code: 'GREATER_THAN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is less than',
        code: 'LESS_THAN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is equal or greater than',
        code: 'GREATER_THAN_OR_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is less than or equal',
        code: 'LESS_THAN_OR_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is between',
        code: 'IS_BETWEEN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is not between',
        code: 'IS_NOT_BETWEEN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'in the following',
        code: 'IN_THE_FOLLOWING',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'not in the following',
        code: 'NOT_IN_THE_FOLLOWING',
      },
    ],
    values: [
      {
        name: '1',
        code: '1',
      },
      {
        name: '2',
        code: '2',
      },
      {
        name: '3',
        code: '3',
      },
      {
        name: '4',
        code: '4',
      },
      {
        name: '5',
        code: '5',
      },
    ],
    time_operator: [
      {
        name: 'on',
        code: 'ON',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'not on',
        code: 'NOT_ON',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'before',
        code: 'BEFORE',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'after',
        code: 'AFTER',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'in the last',
        code: 'IN_THE_LAST',
        values: {},
      },
    ],
  },
  {
    name: 'Cluster',
    code: 'CLUSTER',
    description: '',
    field: 'string',
    operator: [
      {
        type: 'OPERATOR_SELECTION',
        name: 'is',
        code: 'EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is not',
        code: 'NOT_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'contains',
        code: 'CONTAINS',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'does not contain',
        code: 'DOES_NOT_CONTAIN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'start with',
        code: 'STARTS_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'does not start with',
        code: 'DOES_NOT_START_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'end with',
        code: 'ENDS_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'does not end with',
        code: 'DOES_NOT_END_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'in the following',
        code: 'IN_THE_FOLLOWING',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'not in the following',
        code: 'NOT_IN_THE_FOLLOWING',
      },
    ],
    values: [
      {
        name: '4F',
        code: '4F',
      },
      {
        name: 'Busy mom',
        code: 'Busy mom',
      },
      {
        name: 'Offline neighbor',
        code: 'Offline neighbor',
      },
      {
        name: 'On-demand Shoppers',
        code: 'On-demand Shoppers',
      },
      {
        name: 'Single Young Professional',
        code: 'Single Young Professional',
      },
    ],
    time_operator: [
      {
        name: 'on',
        code: 'ON',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'not on',
        code: 'NOT_ON',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'before',
        code: 'BEFORE',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'after',
        code: 'AFTER',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'in the last',
        code: 'IN_THE_LAST',
        values: {},
      },
    ],
  },
  {
    name: 'Customer Type',
    code: 'CUSTOMER_TYPE',
    description: '',
    field: 'string',
    operator: [
      {
        type: 'OPERATOR_SELECTION',
        name: 'is',
        code: 'EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is not',
        code: 'NOT_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'contains',
        code: 'CONTAINS',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'does not contain',
        code: 'DOES_NOT_CONTAIN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'start with',
        code: 'STARTS_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'does not start with',
        code: 'DOES_NOT_START_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'end with',
        code: 'ENDS_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'does not end with',
        code: 'DOES_NOT_END_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'in the following',
        code: 'IN_THE_FOLLOWING',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'not in the following',
        code: 'NOT_IN_THE_FOLLOWING',
      },
    ],
    values: [
      {
        name: 'New',
        code: 'New',
      },
      {
        name: 'Return',
        code: 'Return',
      },
    ],
    time_operator: [
      {
        name: 'on',
        code: 'ON',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'not on',
        code: 'NOT_ON',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'before',
        code: 'BEFORE',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'after',
        code: 'AFTER',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'in the last',
        code: 'IN_THE_LAST',
        values: {},
      },
    ],
  },
  {
    name: 'CLC',
    code: 'LIFE_CYCLE',
    description: '',
    field: 'string',
    operator: [
      {
        type: 'OPERATOR_SELECTION',
        name: 'is',
        code: 'EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is not',
        code: 'NOT_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'contains',
        code: 'CONTAINS',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'does not contain',
        code: 'DOES_NOT_CONTAIN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'start with',
        code: 'STARTS_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'does not start with',
        code: 'DOES_NOT_START_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'end with',
        code: 'ENDS_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'does not end with',
        code: 'DOES_NOT_END_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'in the following',
        code: 'IN_THE_FOLLOWING',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'not in the following',
        code: 'NOT_IN_THE_FOLLOWING',
      },
    ],
    values: [
      {
        name: 'Churn',
        code: 'Churn',
      },
      {
        name: 'Decreasing',
        code: 'Decreasing',
      },
      {
        name: 'Early Stage',
        code: 'Early Stage',
      },
      {
        name: 'First Paying',
        code: 'First Paying',
      },
      {
        name: 'Increasing',
        code: 'Increasing',
      },
      {
        name: 'Maintaining',
        code: 'Maintaining',
      },
    ],
    time_operator: [
      {
        name: 'on',
        code: 'ON',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'not on',
        code: 'NOT_ON',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'before',
        code: 'BEFORE',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'after',
        code: 'AFTER',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'in the last',
        code: 'IN_THE_LAST',
        values: {},
      },
    ],
  },
  {
    name: 'Membership',
    code: 'MEMBERSHIP',
    description: '',
    field: 'string',
    operator: [
      {
        type: 'OPERATOR_SELECTION',
        name: 'is',
        code: 'EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is not',
        code: 'NOT_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'contains',
        code: 'CONTAINS',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'does not contain',
        code: 'DOES_NOT_CONTAIN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'start with',
        code: 'STARTS_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'does not start with',
        code: 'DOES_NOT_START_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'end with',
        code: 'ENDS_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'does not end with',
        code: 'DOES_NOT_END_WITH',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'in the following',
        code: 'IN_THE_FOLLOWING',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'not in the following',
        code: 'NOT_IN_THE_FOLLOWING',
      },
    ],
    values: [
      {
        name: 'Diamond',
        code: 'Diamond',
      },
      {
        name: 'Gold',
        code: 'Gold',
      },
      {
        name: 'Member',
        code: 'Member',
      },
      {
        name: 'Platinum',
        code: 'Platinum',
      },
      {
        name: 'Silver',
        code: 'Silver',
      },
    ],
    time_operator: [
      {
        name: 'on',
        code: 'ON',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'not on',
        code: 'NOT_ON',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'before',
        code: 'BEFORE',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'after',
        code: 'AFTER',
        values: {
          timerange: true,
          frequency: false,
        },
      },
      {
        name: 'in the last',
        code: 'IN_THE_LAST',
        values: {},
      },
    ],
  },
  {
    name: 'Available Coupon',
    code: 'AVAILABLE_COUPON',
    description: '',
    field: 'boolean',
    operator: [
      {
        type: 'OPERATOR_SELECTION',
        name: 'is',
        code: 'EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is not',
        code: 'NOT_EQUAL',
      },
    ],
    values: [
      {
        display_type: 'selection',
        name: 'True',
        code: 'TRUE',
      },
      {
        display_type: 'selection',
        name: 'False',
        code: 'FALSE',
      },
    ],
    time_operator: [],
  },
  {
    name: 'Onelife - Membership Points',
    code: 'ONELIFE_MEMBERSHIP_POINT',
    description: '',
    field: 'number',
    operator: [
      {
        type: 'OPERATOR_SELECTION',
        name: 'is',
        code: 'EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is not',
        code: 'NOT_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is greater than',
        code: 'GREATER_THAN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is less than',
        code: 'LESS_THAN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is equal or greater than',
        code: 'GREATER_THAN_OR_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is less than or equal',
        code: 'LESS_THAN_OR_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is between',
        code: 'IS_BETWEEN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is not between',
        code: 'IS_NOT_BETWEEN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'in the following',
        code: 'IN_THE_FOLLOWING',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'not in the following',
        code: 'NOT_IN_THE_FOLLOWING',
      },
    ],
    values: [],
    time_operator: [],
  },
  {
    name: 'Onelife - Membership Spend',
    code: 'ONELIFE_MEMBERSHIP_SPEND',
    description: '',
    field: 'number',
    operator: [
      {
        type: 'OPERATOR_SELECTION',
        name: 'is',
        code: 'EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is not',
        code: 'NOT_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is greater than',
        code: 'GREATER_THAN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is less than',
        code: 'LESS_THAN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is equal or greater than',
        code: 'GREATER_THAN_OR_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is less than or equal',
        code: 'LESS_THAN_OR_EQUAL',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is between',
        code: 'IS_BETWEEN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'is not between',
        code: 'IS_NOT_BETWEEN',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'in the following',
        code: 'IN_THE_FOLLOWING',
      },
      {
        type: 'OPERATOR_SELECTION',
        name: 'not in the following',
        code: 'NOT_IN_THE_FOLLOWING',
      },
    ],
    values: [],
    time_operator: [],
  },
]

export const formatObjectToArray = (obj) => {
  if (!obj || typeof obj !== 'object') {
    console.error('Input is not a valid object')
    return []
  }

  return Object.entries(obj).map(([key, value]) => ({
    key,
    name: value,
  }))
}

export const formatArrayToObject = (array) => {
  return array.reduce((acc, { key, name }) => {
    acc[key] = name
    return acc
  }, {})
}
