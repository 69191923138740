import CommonInputs from 'Containers/DimensionFilters/Inputs/CommonInputs'
import TimeInputs from 'Containers/DimensionFilters/Inputs/TimeInputs'
import React, { useState, useEffect } from 'react'

export type props = {
  timeOperator: any
  value: string
  onChange: (v: string) => void
  isEditing?: boolean
}

const SegmentTimeInput: React.FC<props> = ({ timeOperator, value, onChange, isEditing }) => {
  const IN_THE_LAST_VALUE_FORMAT = new RegExp(/^\d{1,20} [A-Z]{1,6}$/)

  switch (timeOperator?.name || timeOperator) {
    case 'IN_THE_LAST':
      return (
        <div className="flex">
          <div className="mr-4" style={{ width: 163 }}>
            <CommonInputs
              placeholder="Value"
              value={value?.split(' ')[0]}
              type="NUMBER"
              onChange={(num) => {
                const rangeValue = new RegExp(/\w+$/).exec(value) || []
                onChange(`${num?.toString()} ${rangeValue[0] || 'DAYS'}`)
              }}
            />
          </div>
          <div style={{ width: 163 }}>
            <CommonInputs
              placeholder="Days"
              value={(new RegExp(/\w+$/).exec(value) || [])[0] || ''}
              options={[
                { label: 'Days', value: 'DAYS' },
                { label: 'Weeks', value: 'WEEKS' },
                { label: 'Months', value: 'MONTHS' },
              ]}
              type="SINGLE_SELECT"
              onChange={(range) => {
                const numberValue = new RegExp(/^[0-9]*/).exec(value) || []
                onChange(`${numberValue[0]} ${range}`)
              }}
            />
          </div>
        </div>
      )
    case 'BEFORE':
      return (
        <TimeInputs
          value={value}
          onChange={onChange}
          isFrequencyDisabled={!timeOperator?.values?.frequency}
          isTimeOptionsDisabled={timeOperator?.values?.timerange}
        ></TimeInputs>
      )
    case 'AFTER':
      return (
        <TimeInputs
          value={value}
          onChange={onChange}
          isFrequencyDisabled={!timeOperator?.values?.frequency}
          isTimeOptionsDisabled={timeOperator?.values?.timerange}
        ></TimeInputs>
      )
    case 'ON':
      return (
        <TimeInputs
          value={value}
          onChange={onChange}
          isFrequencyDisabled={!timeOperator?.values?.frequency}
          isTimeOptionsDisabled={!timeOperator?.values?.timerange}
        ></TimeInputs>
      )
    case 'NOT_ON':
      return (
        <TimeInputs
          value={value}
          onChange={onChange}
          isFrequencyDisabled={!timeOperator?.values?.frequency}
          isTimeOptionsDisabled={!timeOperator?.values?.timerange}
        ></TimeInputs>
      )
    default:
      return (
        <div className="flex">
          <div className="mr-4" style={{ width: 163 }}>
            <CommonInputs
              placeholder="Value"
              value={value?.split(' ')[0]}
              type="NUMBER"
              onChange={(num) => {
                const rangeValue = new RegExp(/\w+$/).exec(value) || []
                onChange(`${num?.toString()} ${rangeValue[0] || 'DAYS'}`)
              }}
            />
          </div>
          <div style={{ width: 163 }}>
            <CommonInputs
              placeholder="Days"
              value={(new RegExp(/\w+$/).exec(value) || [])[0] || ''}
              options={[
                { label: 'Days', value: 'DAYS' },
                { label: 'Weeks', value: 'WEEKS' },
                { label: 'Months', value: 'MONTHS' },
              ]}
              type="SINGLE_SELECT"
              onChange={(range) => {
                const numberValue = new RegExp(/^[0-9]*/).exec(value) || []
                onChange(`${numberValue[0]} ${range}`)
              }}
            />
          </div>
        </div>
      )
  }
}

export default SegmentTimeInput
