import React, { useState, useEffect, useRef } from 'react'
import { Select, Input, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { SelectType } from './index'

export type props<T> = {
  options: {
    value: T
    label: string
    tooltip?: string
    sub_options: { value: T; label: string }[]
  }[]
  onChange: ({
    option,
    sub_option,
    deep_link,
  }: {
    option: T
    sub_option: string
    deep_link: string
  }) => void
  value: SelectType<string | number>
}

const DependentSelect: React.FC<props<string | number>> = ({ options, onChange, value }) => {
  const firstRun = useRef(true)
  const [option, setOption] = useState(value?.option || options[0].value || '')
  const [sub_option, setSubOption] = useState(value?.sub_option || '')
  const [deep_link, setDeepLink] = useState(value?.deep_link || '')
  useEffect(() => {
    onChange({ option, sub_option, deep_link })
  }, [])
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false
      return
    }
    onChange({ option, sub_option, deep_link })
  }, [option, sub_option, deep_link])

  const sub_options_selects = options?.find((opt) => opt.value === option)?.sub_options

  return (
    <div className="flex gap-2 mb-2 ">
      <div>
        <div className=" text-secondary_text">Type button</div>
        <div className="flex">
          {options.map((item) => (
            <div
              style={{ height: 40 }}
              onClick={() => {
                setOption(item.value)
              }}
              className={`${item.value === option ? 'toggle-selected' : ''} toggle`}
              key={item.value}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
      <div className="flex-1">
        <div className=" text-secondary_text">Navigate to</div>
        <Select
          disabled={deep_link ? true : false}
          placeholder="Select"
          value={sub_option || undefined}
          onChange={setSubOption}
          options={sub_options_selects}
          allowClear
          style={{ width: '100%' }}
        />
        <div>
          <Input
            style={{ width: '100%' }}
            disabled={sub_option ? true : false}
            placeholder="Paste URL Link"
            value={deep_link || undefined}
            onChange={(e) => setDeepLink(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default DependentSelect
