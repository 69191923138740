import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Select, Alert, Spin } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { AimOutlined, LoadingOutlined } from '@ant-design/icons'

import { getCampaignChannels } from 'Services/campaigns'
import { formatErrors } from 'Utils'
import { CampaignPlacement } from 'CampaignTypes'
import { States } from 'Models/campaign'
import PlacementList from './PlacementList.component'
import Tags from './Tag'
import { createTag, getCreatedTags } from 'Services/campaign-tags'
import { useParams } from 'react-router-dom'
const { Option } = Select
const zaloZns = [
  {
    id: 9,
    created_at: '2023-02-24T03:21:09.355338+00:00',
    updated_at: '2023-02-24T03:21:09.355338+00:00',
    name: 'Zalo',
    type: 'zalo',
    description: null,
    placement_arr: [
      {
        id: 10,
        created_at: '2023-02-24T03:24:55.004323+00:00',
        updated_at: '2023-02-24T03:24:55.004323+00:00',
        name: 'ZNS by registered template ID',
        code: 'zns_by_template_id',
        schemas: null,
        description: 'Send ZNS to all your users by using approved template ID',
      },
    ],
  },
]
export default function Overview() {
  const { selected_placement, placements, selected_channel, content, campaign_tag_objs, channels } =
    useStoreState((state) => state.campaignModule)
  // const channels = zaloZns
  const { setState } = useStoreActions((actions: any) => actions.campaignModule)
  const [errors, setErrors] = useState('')
  const [tagValue, setTagValue] = useState([])
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const { id } = useParams()

  const tempTagObj = campaign_tag_objs?.map((tag) => {
    return { id: tag.id, value: tag.name, color: tag.color }
  })
  const getTagList = async () => {
    if (tagValue.length) return
    try {
      const resp = await getCreatedTags()
      const tempValue: any = resp?.data?.map((item: any) => {
        return { color: item.color, value: item.name, id: item.id }
      })
      setTagValue(tempValue)
    } catch (e) {}
  }

  const onChannelChange = (value) => {
    const channel = channels.find((channel) => channel.name === value)
    if (channel?.placement_arr.length) {
      setState({ key: 'placements', value: channel?.placement_arr || [] })
      setState({ key: 'selected_channel', value: channel })
    }
  }

  const onSelectPlacement = (plm: CampaignPlacement) => {
    setState({ key: 'content', value: {} })
    setState({ key: 'selected_placement', value: plm })
  }

  useEffect(() => {
    if (channels.length) return
    const handleFetchChannels = async () => {
      try {
        const resp = await getCampaignChannels()
        setState({ key: 'channels', value: resp.data })
      } catch (err: any) {
        setErrors(formatErrors(err.response?.data))
      }
    }
    handleFetchChannels()
  }, [setState, channels])

  useEffect(() => {
    if (!selected_placement) return
    if (['link_clicks', 'post_engagement'].includes(selected_placement.code)) return

    setState({ key: 'kpi', value: undefined })
    setState({ key: 'daily_budget', value: undefined })
    setState({ key: 'bid_amount', value: undefined })
  }, [selected_placement, setState])

  // const { id } = useParams()
  // useEffect(() => {
  //   getTagList()
  // }, [])

  return (
    <div className="OverviewStep">
      {errors && <Alert type="error" message={errors} />}
      <div style={{ width: 368 }}>
        <h3 className="mb-2">Channel</h3>
        <Select
          value={selected_channel?.name}
          onChange={(value) => onChannelChange(value)}
          style={{ width: '100%' }}
          placeholder="Select a channel"
        >
          {channels.map((channel, index) => (
            <Option key={index} value={channel?.name}>
              {channel?.name}
            </Option>
          ))}
        </Select>
      </div>
      <PlacementList
        placements={placements}
        onSelectPlacement={onSelectPlacement}
        selectedPlacement={selected_placement}
      ></PlacementList>
      {/* <div className="mt-6">
        {tagValue?.length ? (
          <Tags
            value={id ? (tempTagObj?.length ? tempTagObj : []) : tagValue?.length ? tagValue : []}
          />
        ) : (
          <Spin indicator={antIcon} />
        )}
      </div> */}
    </div>
  )
}
