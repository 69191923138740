import { Modal, Upload, message } from 'antd'
import type { RcFile, UploadChangeParam } from 'antd/lib/upload'
import axios from 'axios'

import React, { useState } from 'react'
import { RiAddLine, RiDeleteBinLine, RiImageAddLine } from 'react-icons/ri'
import { LinkOutlined, DeleteOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { set } from 'lodash'
import './index.scss'
const { Dragger } = Upload

const UploadFiles = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const [previewImage, setPreviewImage] = useState('')
  const endpoint =
    (process.env.REACT_APP_API_HOST ?? '') +
    process.env.REACT_APP_API_VERSION +
    process.env.REACT_APP_UPLOAD_PRODUCT_URL +
    '?company=kingfood'
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewTitle, setPreviewTitle] = useState('')
  const [checkShow, setCheckShow] = useState<'video' | 'image' | 'hide'>('hide')
  const [videoSrc, setVideoSrc] = useState('')
  const [filesList, setFilesList] = useState<
    {
      url: string
      name: string
      type: string
      uid: string
    }[]
  >([])
  const [error, setError] = useState<string>()

  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file } = options
    setError('')
    const fmData = new FormData()
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `${localStorage.getItem('Authorization')}`,
      },
    }
    fmData.append('images', file)
    try {
      const res = await axios.post(endpoint, fmData, config)

      onSuccess(
        setFilesList((prevState) => [
          ...prevState,
          {
            url: res?.data[0]?.url,
            name: file.name,
            type: file.type,
            uid: file.uid,
          },
        ])
      )
    } catch (err) {
      onError(
        setError(
          file.type.includes('image')
            ? 'Vui lòng tải lên định dạng PNG, JPG, HEIC và nhỏ hơn 10MB'
            : 'Vui lòng tải lên định dạng MP4, MOV  và nhỏ hơn 20MB'
        )
      )
    }
  }
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (err) => reject(err)
    })

  const handlePreview = async (file) => {
    if (file?.type?.includes('image')) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile) // Provide the correct type for file.originFileObj
      }

      setPreviewImage(file.url || (file.preview as string))
      setPreviewOpen(true)
      setCheckShow('image')
      setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
    } else if (file?.type?.includes('video')) {
      if (file?.originFileObj) {
        const url = URL.createObjectURL(file.originFileObj)
        setCheckShow('video')
        setPreviewOpen(true)
        setPreviewTitle(file.name)
        setVideoSrc(url || file.url || (file.preview as string))
      }
    }
  }

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Link copied',
      duration: 1,
      className: 'CustomSuccessCopy',
    })
  }

  const copyText = (text) => {
    if (document) {
      const copyContent = async () => {
        try {
          await navigator.clipboard.writeText(text || '')
          success()
        } catch (err) {
          console.error('Failed to copy: ', err)
        }
      }
      copyContent()
    }
  }

  return (
    <div>
      {contextHolder}
      <div className="no-scrollbar h-[88vh] overflow-scroll lg:h-[80vh]">
        <div>
          <Upload
            customRequest={uploadImage}
            listType={'picture'}
            onRemove={(file) => {
              setFilesList((prevState) => {
                const newState = prevState.filter((item) => item.uid !== file.uid)
                return newState
              })
              setPreviewOpen(false)
            }}
            onPreview={handlePreview}
            showUploadList={{
              showPreviewIcon: true,
              showRemoveIcon: true,
            }}
            itemRender={(originNode, file) => {
              const item = filesList?.find((i) => i.uid === file.uid)

              return (
                <div className={` ${item ? '' : 'hidden'}  flex gap-2 items-center mb-4`}>
                  <div
                    style={{ width: 56, height: 56 }}
                    className=" rounded w-[56px] h-[56px] flex items-center justify-center bg-brand_light cursor-pointer"
                    onClick={() => {
                      if (item?.type?.includes('video')) {
                        setPreviewOpen(true)
                        setCheckShow('video')
                        setPreviewTitle(file.name)
                        setVideoSrc(item?.url)
                      } else {
                        setPreviewImage(item?.url || '')
                        setPreviewOpen(true)
                        setCheckShow('image')
                        setPreviewTitle(file.name)
                      }
                    }}
                  >
                    {item?.type?.includes('video') ? (
                      <VideoCameraOutlined className=" text-brand_primary" size={32} />
                    ) : (
                      <img
                        style={{ width: 56, height: 56 }}
                        className=" rounded object-cover"
                        src={item?.url}
                      ></img>
                    )}
                  </div>
                  <div id="ImageInputURL" className=" truncate flex-1">
                    {item?.url}
                  </div>
                  <div className=" flex items-center gap-4">
                    <LinkOutlined
                      className=" hover:cursor-pointer"
                      onClick={() => copyText(`{{image: ${item?.url}}}`)}
                    />
                    <div>
                      <DeleteOutlined
                        className=" hover:cursor-pointer"
                        onClick={() => {
                          setFilesList(filesList.filter((i) => i.uid !== file.uid))
                        }}
                      />
                    </div>
                  </div>
                </div>
              )
            }}
            progress={{ strokeWidth: 2, showInfo: false }}
            className={`${
              filesList?.length > 0
                ? 'mb-4 flex flex-col-reverse'
                : 'flex items-center bg-brand_light p-4 justify-center gap-2 rounded border border-dotted'
            } py-2 `}
          >
            <div>
              {(() => {
                if (filesList?.length > 0 || error) {
                  return (
                    <div className="flex items-center justify-center gap-4 p-5 bg-brand_light rounded border border-dotted">
                      <RiImageAddLine size={20} className=" text-brand_primary" />
                      <div>Upload Image</div>
                    </div>
                  )
                }
                return (
                  <div className="flex items-center justify-center gap-4 flex-col">
                    <RiImageAddLine size={32} className=" text-brand_primary" />
                    <div className=" flex flex-col items-center justify-center">
                      <div>
                        Drag and drop or <span className=" text-brand_primary">browse</span> to
                        choose a file
                      </div>
                      <div className=" opacity-40">(JPG, PNG and SVG support)</div>
                    </div>
                  </div>
                )
              })()}
            </div>
          </Upload>
          {error ? <div className="mx-4 text-xs text-red-500">{error}</div> : null}
        </div>
      </div>
      <Modal
        visible={previewOpen}
        title={`${previewTitle?.substring(0, 10)}...`}
        footer={null}
        onCancel={() => {
          setPreviewOpen(false)
          setCheckShow('hide')
        }}
        destroyOnClose
      >
        {checkShow === 'image' ? (
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        ) : null}
        {checkShow === 'video' ? (
          <video controls style={{ width: '100%', height: '50vh' }}>
            <source src={videoSrc} type="video/mp4" />
          </video>
        ) : null}
      </Modal>
    </div>
  )
}

export default UploadFiles
