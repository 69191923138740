import React from 'react'
import { AutoComplete, Form, Input, message, Select, Skeleton } from 'antd'
import { Schema } from 'CampaignTypes'
import ChannelSchemas from 'Constants/ChannelSchemas'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useEffect, useState } from 'react'
import { getParameterSegment, getZnsTemplateById } from 'Services/campaigns'
import { getMessageParams } from 'Services/store-farming'
import FormItem from './FormItem'
import { getZnsTemplateByIdNew } from 'Services/new-cj'
export type ParamType = { parameter_code: string }
export type ParametersType = {
  group_name: string
  group_code: string
  parameters: ParamType[]
}

const FormItemZNS = () => {
  const [form] = Form.useForm()
  const { content, audience_id, selected_dynamic_param } = useStoreState(
    (state) => state.campaignModule
  )
  const { setState } = useStoreActions((action: any) => action.campaignModule)
  const [templateId, setTemplateId] = useState<any[]>()
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>()
  const [loadingTemplate, setLoadingTemplate] = useState(false)
  const [loadingDimension, setLoadingDimension] = useState(false)
  const [template_data, setTemplateData] = useState<any[]>([])
  const handleFetchData = async () => {
    const paramsResp = await getMessageParams()
    const paramData: ParametersType[] = paramsResp.data
    const flat = paramData?.map((item) => {
      return item.parameters.map((i) => i.parameter_code?.replace(/{{|}}/gi, ''))
    })
    var listDefaultDimensions: any = []
    flat?.forEach((item) => {
      listDefaultDimensions = [...listDefaultDimensions, ...item]
    })
    setTemplateData(listDefaultDimensions)
  }
  const formatArrayToObject = (array) => {
    return array.reduce((acc, { key, name }) => {
      acc[key] = name
      return acc
    }, {})
  }
  const formatObjectToArray = (obj) => {
    if (!obj || typeof obj !== 'object') {
      console.error('Input is not a valid object')
      return []
    }

    return Object.entries(obj).map(([key, value]) => ({
      key,
      name: value,
    }))
  }
  useEffect(() => {
    handleFetchData()
  }, [])
  useEffect(() => {
    const getTemplate = async () => {
      setLoadingTemplate(true)
      try {
        const resp = await getZnsTemplateByIdNew()
        setTemplateId(resp?.data)
      } catch (error) {
        setLoadingTemplate(false)
        console.log('something went wrong!')
        setTemplateId([])
      }
      setLoadingTemplate(false)
    }
    if (audience_id) {
      getTemplate()
    }
  }, [audience_id])

  useEffect(() => {
    if (content?.template_id) {
      setSelectedTemplateId(content['template_id'])
      form.setFieldsValue({ template_id: content['template_id'] })
      setState({
        key: 'content',
        value: {
          ...content,
          template_id: content['template_id'],
          template_name: content['template_name'],
        },
      })
    } else if (!selectedTemplateId) {
      setSelectedTemplateId(templateId?.[0]?.template_id)
      setState({
        key: 'content',
        value: {
          ...content,
          template_id: templateId?.[0]?.template_id,
          template_name: templateId?.[0]?.template_name,
        },
      })
    }
  }, [templateId])

  useEffect(() => {
    if (templateId?.length) {
      setState({
        key: 'content',
        value: {
          ...content,
          template_name: templateId?.filter((item) => item.template_id === selectedTemplateId)[0]
            .template_name,
        },
      })
    }
  }, [selectedTemplateId])

  const onFinish = () => {
    message.success('Content is validated')
    setState({ key: 'isContentValidated', value: true })
  }

  const onChangeValue = (name: string, value?: string) => {
    setSelectedTemplateId(value)
    form.setFieldsValue({ [name]: value })

    setState({ key: 'isContentValidated', value: false })
    setState({ key: 'content', value: { ...content, [name]: value, template_data: [] } })
  }

  const onChangeDimension = (name, value) => {
    form.setFieldsValue({ [name]: value })
    const templateDataArray = formatObjectToArray(content?.template_data || {})
    const existingItemIndex = templateDataArray.findIndex((item) => item.key === name)
    if (existingItemIndex !== -1) {
      templateDataArray[existingItemIndex] = { key: name, name: value }
    } else {
      templateDataArray.push({ key: name, name: value })
    }
    setState({
      key: 'content',
      value: { ...content, template_data: formatArrayToObject(templateDataArray) },
    })
  }
  const getValueContentParams = (name) => {
    const getItem = formatObjectToArray(content?.template_data)?.filter((item) => item.key === name)

    if (getItem?.length) {
      return getItem[0]?.name
    } else {
      return undefined
    }
  }
  const getDefaultValueContentParams = (name) => {
    if (!content?.template_data || typeof content?.template_data !== 'object') {
      console.error('Input is not a valid object')
      return undefined
    }
    const getItem = formatObjectToArray(content?.template_data).filter((item) => item.key === name)

    if (getItem.length) {
      return getItem[0]?.name
    } else {
      return undefined
    }
  }

  if (loadingTemplate)
    return (
      <>
        <Skeleton active></Skeleton>
      </>
    )
  return (
    <div>
      <Form
        layout="vertical"
        name="ContentForm"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 24 }}
        requiredMark
        onFinish={onFinish}
        initialValues={content}
        scrollToFirstError
        form={form}
      >
        <Form.Item key={'template_id'} label={'Template ID'} name={'template_id'}>
          <Select
            onChange={(value: any) => {
              onChangeValue('template_id', value)
              setSelectedTemplateId(value)
            }}
            options={templateId?.map((item) => ({
              value: item?.template_id,
              label: item?.template_name,
            }))}
            defaultValue={content && content['template_id']}
          >
            {templateId?.map((item) => (
              <Select.Option key={item?.template_id} value={item?.template_id}>
                {item?.template_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item key={'template_name'} label={'Template Name'}>
          <div style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }} className="px-4 py-2 rounded ">
            {content?.template_name || ''}
          </div>
        </Form.Item>
        {loadingDimension ? (
          <Skeleton active></Skeleton>
        ) : (
          templateId?.length &&
          templateId
            ?.filter((i) => i.template_id === selectedTemplateId)[0]
            ?.params?.map((item, index) => (
              <Form.Item key={item?.name} label={`parameter ${index + 1}`}>
                <Input disabled value={item?.name} />
                <Input
                  onChange={(e) => {
                    onChangeDimension(item?.key, e.target.value)
                    setState({
                      key: 'selected_dynamic_param',
                      value: {
                        key: item?.key,
                        value: e.target.value,
                        position: e?.target?.selectionStart,
                        ZNSKey: item?.key,
                        ZNSValue: item?.value,
                      },
                    })
                  }}
                  value={getValueContentParams(item?.key) as any}
                  onFocus={(e) => {
                    setState({
                      key: 'selected_dynamic_param',
                      value: {
                        ...selected_dynamic_param,
                        key: item?.key,
                        position: e?.target?.selectionStart,
                        ZNSKey: item?.key,
                        ZNSValue: item?.value,
                      },
                    })
                  }}
                  onClick={(e) => {
                    setState({
                      key: 'selected_dynamic_param',
                      value: {
                        ...selected_dynamic_param,
                        position: e.currentTarget.selectionStart,
                      },
                    })
                  }}
                />
              </Form.Item>
            ))
        )}
        {loadingDimension ? (
          <Skeleton active></Skeleton>
        ) : (
          <>
            {templateId?.length
              ? Object?.entries(
                  templateId?.filter((i) => i?.template_id === selectedTemplateId)[0]?.[
                    'template_data'
                  ]
                )
                  ?.map(([key, value]) => {
                    return {
                      key,
                      name: value,
                    }
                  })
                  ?.map((item, index) => (
                    <Form.Item key={item?.key} label={`${item?.key}`}>
                      {/* <Input disabled value={item?.name} /> */}
                      <AutoComplete
                        placeholder="Input value or select from list"
                        onSelect={(value) => {
                          onChangeDimension(item?.key, value)
                        }}
                        onBlur={(value) => {
                          onChangeDimension(item?.key, (value.target as any)?.value)
                        }}
                        defaultValue={getDefaultValueContentParams(item?.key) as any}
                        onChange={(value) => {
                          onChangeDimension(item?.key, value)
                          setState({
                            key: 'selected_dynamic_param',
                            value: {
                              ...selected_dynamic_param,
                              ZNSValue: value,
                              ZNSKey: item?.key,
                              ZNSSource: 'cj',
                            },
                          })
                        }}
                        value={content?.template_data?.[item.key] as any}
                        children={
                          <Input
                            onChange={(e) => {
                              onChangeDimension(item?.key, e.target.value)
                              setState({
                                key: 'selected_dynamic_param',
                                value: {
                                  key: item?.key,
                                  value: e.target.value,
                                  position: e?.target?.selectionStart,
                                  ZNSKey: item?.key,
                                  ZNSValue: e.target.value,
                                  ZNSSource: 'cj',
                                },
                              })
                            }}
                            value={getValueContentParams(item?.key) as any}
                            onFocus={(e) => {
                              setState({
                                key: 'selected_dynamic_param',
                                value: {
                                  ...selected_dynamic_param,
                                  key: item?.key,
                                  position: e?.target?.selectionStart,
                                  ZNSKey: item?.key,
                                  ZNSValue: e.target.value,
                                  ZNSSource: 'cj',
                                },
                              })
                            }}
                            onClick={(e) => {
                              setState({
                                key: 'selected_dynamic_param',
                                value: {
                                  ...selected_dynamic_param,
                                  position: e.currentTarget.selectionStart,
                                  ZNSSource: 'cj',
                                },
                              })
                            }}
                          />
                        }
                      />
                    </Form.Item>
                  ))
              : null}
          </>
        )}
      </Form>
    </div>
  )
}

export default FormItemZNS
