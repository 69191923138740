import React, { useEffect, useState, useRef } from 'react'
import { Drawer, Dropdown, Input, Popover, Select, Spin } from 'antd'
import useOnClickOutside from './useOnClickOutside'
import { getTemplateMessages, getMessageParams } from 'Services/store-farming'

import './DynamicContent.scss'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { States as CampaignStates } from 'Models/campaign'
import LoadingIcon from 'Components/LoadingIcon'
export type ParamType = { parameter_code: string }

export type ParametersType = {
  group_name: string
  group_code: string
  parameters: ParamType[]
}
const DynamicContent = ({
  value,
  onChange,
  placeholder,
  defaultValue = '',
  dynamicParams,
  fieldName,
  row,
}: {
  value?: any
  onChange?: any
  placeholder?: any
  defaultValue?: any
  dynamicParams?: any
  fieldName?: any
  row?: any
}) => {
  const { selected_placement, content, selected_dynamic_param } = useStoreState(
    (state) => state.campaignModule
  )
  const { setState } = useStoreActions((state: any) => state.campaignModule)

  return (
    <div className="DynamicContentComponent">
      <Input.TextArea
        defaultValue={defaultValue}
        value={content?.[fieldName] || value || ''}
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e.target.value)
          setState({
            key: 'selected_dynamic_param',
            value: { key: fieldName, value: e.target.value, position: e?.target?.selectionStart },
          })
        }}
        rows={row || 3}
        onClick={(e) => {
          setState({
            key: 'selected_dynamic_param',
            value: {
              ...selected_dynamic_param,
              position: e.currentTarget.selectionStart,
            },
          })
        }}
        onFocus={(e) => {
          setState({
            key: 'selected_dynamic_param',
            value: {
              key: fieldName,
              value: e.currentTarget.value,
              position: e.currentTarget.selectionStart,
            },
          })
        }}
      />
      {selected_placement?.code == 'sms' && (
        <div style={{ fontSize: 12, color: 'rgba(0,0,0,0.6)', paddingTop: 8 }}>
          A single message contains 160 characters
        </div>
      )}
    </div>
  )
}

export default DynamicContent
