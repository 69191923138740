import ImageField from 'Components/FormItem/Image'
import { Form, Input, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useStoreActions, useStoreState } from 'easy-peasy'
import React from 'react'
import UploadFiles from './UploadFile'

export default function ZaloGroupForm() {
  const [form] = Form.useForm()
  const { setState } = useStoreActions((action: any) => action.campaignModule)
  const { content } = useStoreState((state: any) => state.campaignModule)
  const onFormChange = (name, value) => {
    setState({ key: 'content', value: { ...content, [name]: value } })
  }

  return (
    <Form
      layout="vertical"
      name="ContentForm"
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 24 }}
      requiredMark
      onFinish={(value) => {
        message.success('Content is validated')
        setState({ key: 'isContentValidated', value: true })
        setState({ key: 'content', value })
      }}
      initialValues={content}
      scrollToFirstError
      form={form}
      onFieldsChange={(field) => {
        const _field = field[0]
        setState({ key: 'content', value: { ...content, [_field.name[0]]: _field.value } })
      }}
    >
      <Form.Item key={'content'} label={'Content'} name={'content'}>
        <TextArea />
      </Form.Item>
      <div>
        <div style={{ marginBottom: 10 }} className=" font-semibold text-secondary_text">
          Image / video
        </div>
        <UploadFiles />
      </div>

      <Form.Item
        key={'utm_source'}
        label={'UTM Source'}
        name={'utm_source'}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        key={'utm_medium'}
        label={'UTM Medium'}
        name={'utm_medium'}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        key={'utm_campaign'}
        label={'UTM Campaign'}
        name={'utm_campaign'}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}
