import { Form, Input, message, Select } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import React, { useEffect, useState } from 'react'
import LimitInput from './LimitInput'
import useFetchProducts from './useFetchProducts'
import { DeleteOutlined } from '@ant-design/icons'
import { useDebounce } from 'Hooks'
import usePromotionListing from 'Components/CJPopover/NodeConfigs/PromotionNode/usePromotionListing'
import ProductLabel from './ProductLabel'
const { Option } = Select

const INIT_VALUE = {
  promotion_1: {
    promotionId: '',
    script_lines: ['', ''],
    product: {},
    isSelected: true,
  },
  promotion_2: {
    promotionId: '',
    script_lines: ['', ''],
    product: {},
    isSelected: false,
  },
  selectedPromotion: 'promotion_1',
}
export default function Kpos() {
  const { content } = useStoreState((state) => state.campaignModule)
  const { setState } = useStoreActions((action: any) => action.campaignModule)
  const [showSecondProduct, setShowSecondProduct] = useState(
    content?.promotion_2?.promotionId ? true : false
  )
  const [selectedPromotion, setSelectedPromotion] = useState('promotion_1')
  const onFormChange = (name, value) => {
    setState({ key: 'content', value: { ...content, [name]: value } })
  }

  useEffect(() => {
    setState({
      key: 'content',
      value: {
        ...INIT_VALUE,
        ...content,
        selectedPromotion: selectedPromotion,
      },
    })
  }, [selectedPromotion])

  const { data, loading, hasMore, loadMore } = usePromotionListing({
    params: {
      channel_kpos: true,
    },
  })
  const handleScroll = (event) => {
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight
    if (bottom && hasMore && !loading) {
      loadMore()
    }
  }

  return (
    <Form
      className="sf-form"
      layout="vertical"
      name="ContentForm"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 24 }}
      onFinish={(value) => {
        message.success('Content is validated')
        setState({ key: 'isContentValidated', value: true })
      }}
    >
      <Form.Item
        key="promotion_1"
        label=""
        style={{
          backgroundColor: selectedPromotion === 'promotion_1' ? '#E6F7FF' : '',
          padding: '16px',
        }}
      >
        <div onMouseEnter={() => setSelectedPromotion('promotion_1')} style={{ cursor: 'pointer' }}>
          <label style={{ display: 'block', marginBottom: '8px' }}>Promotion 1</label>
          <Select
            showSearch
            value={content?.promotion_1?.promotionId}
            style={{ width: '100%', minWidth: 300, marginBottom: '8px' }}
            placeholder="Select a promotion"
            optionFilterProp="children"
            onPopupScroll={handleScroll}
            onChange={(value) => {
              onFormChange('promotion_1', { ...content?.promotion_1, promotionId: value })
            }}
            filterOption={false}
          >
            {data.map((promotion) => (
              <Option key={promotion.id} value={promotion.id}>
                {promotion.name}
              </Option>
            ))}
          </Select>
          <label style={{ display: 'block', marginBottom: '8px' }}>Sản phẩm</label>
          <ProductLabel
            promotionId={content?.promotion_1?.promotionId}
            onChange={onFormChange}
            promotionName="promotion_1"
            content={content}
          ></ProductLabel>
          {content.promotion_1?.script_lines?.map((item, index) => {
            return (
              <div key={index} className="mt-2">
                <label style={{ display: 'block', marginBottom: '8px' }}>
                  Script line {index + 1}
                </label>
                <LimitInput
                  onChange={(e) => {
                    const temp = content?.promotion_1?.script_lines
                    temp[index] = e.target.value
                    onFormChange('promotion_1', { ...content?.promotion_1, script_lines: temp })
                  }}
                  value={item}
                ></LimitInput>
              </div>
            )
          })}
        </div>
      </Form.Item>
      {showSecondProduct ? (
        <Form.Item
          key="promotion_2"
          label=""
          className="relative"
          style={{
            backgroundColor: selectedPromotion === 'promotion_2' ? '#E6F7FF' : '',
            padding: '16px',
          }}
        >
          <div
            onMouseEnter={() => setSelectedPromotion('promotion_2')}
            style={{ cursor: 'pointer' }}
          >
            <DeleteOutlined
              style={{ position: 'absolute', right: '0px', top: '0px', cursor: 'pointer' }}
              onClick={() => {
                setSelectedPromotion('promotion_1')
                onFormChange('promotion_2', {
                  promotionId: '',
                  script_lines: ['', ''],
                  product: {},
                  isSelected: false,
                })
                setShowSecondProduct(false)
              }}
            ></DeleteOutlined>
            <label style={{ display: 'block', marginBottom: '8px' }}>Promotion 1</label>
            <Select
              showSearch
              value={content?.promotion_2?.promotionId}
              style={{ width: '100%', minWidth: 300, marginBottom: '8px' }}
              placeholder="Select a promotion"
              optionFilterProp="children"
              onPopupScroll={handleScroll}
              onChange={(value) => {
                onFormChange('promotion_2', { ...content?.promotion_2, promotionId: value })
              }}
              filterOption={false}
            >
              {data.map((promotion) => (
                <Option key={promotion.id} value={promotion.id}>
                  {promotion.name}
                </Option>
              ))}
            </Select>
            <label style={{ display: 'block', marginBottom: '8px' }}>Sản phẩm</label>
            <ProductLabel
              promotionId={content?.promotion_2?.promotionId}
              onChange={onFormChange}
              promotionName="promotion_2"
              content={content}
            ></ProductLabel>
            {content?.promotion_2?.script_lines?.map((item, index) => {
              return (
                <div key={index} className="mt-2">
                  <label style={{ display: 'block', marginBottom: '8px' }}>
                    Script line {index + 1}
                  </label>
                  <LimitInput
                    onChange={(e) => {
                      const temp = content?.promotion_2.script_lines
                      temp[index] = e.target.value
                      onFormChange('promotion_2', { ...content?.promotion_2, script_lines: temp })
                    }}
                    value={item}
                  ></LimitInput>
                </div>
              )
            })}
          </div>
        </Form.Item>
      ) : (
        <div
          style={{ maxWidth: '100%', textAlign: 'center' }}
          className=" cursor-pointer font-semibold mb-3"
          onClick={() => {
            setShowSecondProduct(true)
            setSelectedPromotion('promotion_2')
          }}
        >
          + Add promotion
        </div>
      )}
    </Form>
  )
}
