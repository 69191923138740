import React, { useCallback, useEffect, useState } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { Input } from 'antd'

import Select from './Select'
import SearchProduct from './SearchProduct'
import axios from 'Utils/axios'

export type SelectType<T = string | number> = {
  option: T
  sub_option: string
  title?: string
  deep_link?: string
}

export type props<T> = {
  options: {
    value: T
    label: string
    sub_options: { value: T; label: string }[]
  }[]
  value: SelectType[]
  title?: string
  selectsLimit?: number
  onChange: (value: SelectType<T>[]) => void
}

const ButtonSelectsDeepLink: React.FC<props<string | number>> = ({
  options,
  onChange,
  value: selects,
  title,
  selectsLimit = 2,
}) => {
  const [deeplinks, setDeeplinks] = useState<any>([])
  const handleGetOptions = async () => {
    const res = await axios.get('/channels/deeplinks')
    setDeeplinks([
      {
        value: 'primary',
        label: 'Primary',
        sub_options: res?.data?.map((item) => ({
          value: item?.deeplink,
          label: item?.name,
        })),
      },
      {
        value: 'secondary',
        label: 'Secondary',
        sub_options: res?.data?.map((item) => ({
          value: item?.deeplink,
          label: item?.name,
        })),
      },
    ])
  }

  useEffect(() => {
    handleGetOptions()
  }, [])
  const renderDependentSelect = ({ index, select }) => {
    const onChangeSelect = (selectValue: SelectType, index: number) => {
      let temp = selects
      temp[index] = { ...temp[index], ...selectValue }

      onChange(temp)
    }

    const onChangeTitle = (value: string, index: number) => {
      let temp = selects
      temp[index] = { ...temp[index], title: value }

      onChange(temp)
    }

    return (
      <div className="w-full">
        <div className="flex items-center gap-4">
          <Select
            key={index}
            value={select}
            options={deeplinks}
            onChange={(value: SelectType) => onChangeSelect(value, index)}
          />
        </div>
        <Input
          className="dependent-select__input"
          placeholder="Title"
          value={select?.title}
          onChange={(e) => onChangeTitle(e.target.value, index)}
        />
      </div>
    )
  }

  return (
    <div>
      {selects.map((select, index) => (
        <div className="mt-2 mb-6" key={index}>
          <div className="flex w-full justify-between">
            <h4 className="mb-2 text-secondary_text">
              {title} {index + 1}
            </h4>
            {
              <div
                className="cursor-pointer"
                onClick={() => {
                  const temp = selects
                  temp.splice(index, 1)

                  onChange(temp)
                }}
              >
                <DeleteOutlined />
              </div>
            }
          </div>
          {renderDependentSelect({ index, select })}
        </div>
      ))}
      {selects.length < selectsLimit && (
        <div
          className="w-full text-center cursor-pointer font-semibold"
          onClick={() => {
            onChange([...selects, { option: 'primary', sub_option: '' }])
          }}
        >
          + Add more
        </div>
      )}
    </div>
  )
}

export default ButtonSelectsDeepLink
