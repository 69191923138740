import React, { useState, useEffect } from 'react'
import { LeftOutlined } from '@ant-design/icons'
import { Select, InputNumber, Button, Input, message } from 'antd'
import { CustomerItem, Segment } from 'DimensionsFiltersTypes'

import TimeInput from './Customer.SegmentScreen.TimeInput'
import SearchDropdown from 'Containers/DimensionFilters/Inputs/CommonInputs/SearchDropdown'
import useFetchDimensions from './useFetchDimensions'
import { stringify } from 'query-string'
import { TREE_DIMENSIONS } from 'Containers/DimensionFilters/constants'
import TreeSelect from 'Containers/DimensionFilters/Inputs/CommonInputs/TreeSelect'
import CommonInputs from 'Containers/DimensionFilters/Inputs/CommonInputs'
import './index.scss'
const SegmentScreen = ({
  segments,
  isEditing,
  initialValues,
  segmentValues,
  setSegmentValues,
}: {
  segments: any
  isEditing?: boolean
  initialValues?: any
  segmentValues?: any
  setSegmentValues?: any
}) => {
  const [segment, setSegment] = useState<any>()

  const { options, setKeyword } = useFetchDimensions({
    dimension_code: segment?.code,
  })

  const getValues = (values, v) => {
    const item = values?.find((e) => e?.code === v)
    return item?.values
  }

  const checkNumRegex =
    /[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ_<>=^\s+?|$!@#$%\\^&*)(_'"`~/:;{}\[\]-][a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ_<>=^\s+?|$!@#$%\\^&*)(_'"`~/\[\]-]*/
  function isJsonString(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }
  useEffect(() => {
    setSegmentValues({
      value: initialValues?.value || '',
      count: initialValues?.count?.toString() || '',
      operator: initialValues?.operator || '',
      time_operator: initialValues?.time_operator || '',
      time_value: initialValues?.time_value || '',
      type: initialValues?.type || '',
    })
    initialValues?.type &&
      setSegment(segments.find((segment) => segment.code === initialValues?.type))
  }, [initialValues])

  const renderInput = () => {
    if (segment?.field === 'number') {
      return (
        <Input
          style={{ height: 40, borderRadius: 4, width: 150 }}
          placeholder="Value"
          value={segmentValues?.value}
          onChange={(e) => {
            e.target.value
              .toString()
              .split(',')
              .filter((e) => e != '')
              .some((e) => e.normalize('NFC').match(checkNumRegex)) &&
              message.warning("Invalid input: input couldn't be text", 0.75)
            setSegmentValues({ ...segmentValues, value: e.target.value.toString() })
          }}
        />
      )
    } else {
      return (
        <Input
          style={{ height: 40, borderRadius: 4, width: 150 }}
          placeholder="Value"
          value={segmentValues?.value}
          onChange={(e) => {
            !e.target.value
              .toString()
              .split(',')
              .filter((e) => e != '')
              .every((e) => e.normalize('NFC').match(checkNumRegex)) &&
              message.warning("Invalid input: input couldn't be number", 0.75)
            setSegmentValues({ ...segmentValues, value: e.target.value.toString() })
          }}
        />
      )
    }
  }
  const isJson = isJsonString(segmentValues?.value)

  const renderOptionsInput = () => {
    if (TREE_DIMENSIONS?.includes(segment?.code))
      return (
        <TreeSelect
          values={
            segmentValues?.value
              ? isJson
                ? (() => {
                    try {
                      const parsed = JSON.parse(segmentValues?.value)
                      // Ensure the result is always an array and convert each element to a string
                      return Array.isArray(parsed) ? parsed.map(String) : [String(parsed)]
                    } catch (error) {
                      console.error('Failed to parse JSON:', error)
                      return []
                    }
                  })()
                : segmentValues?.value.split(';').filter(Boolean).map(String) // Ensure all values are strings
              : []
          }
          onChange={(value) => {
            setSegmentValues({ ...segmentValues, value: JSON.stringify(value) })
          }}
          handleSearch={(keyword) => setKeyword(keyword)}
          options={options}
        />
      )
    if (segment?.values?.length) {
      if (
        segmentValues?.operator === 'IN_THE_FOLLOWING' ||
        segmentValues?.operator === 'NOT_IN_THE_FOLLOWING'
      ) {
        return (
          <SearchDropdown
            placeholder="Value"
            values={
              segmentValues?.value
                ? isJson
                  ? JSON.parse(segmentValues?.value)
                  : segmentValues?.value?.split(';')
                : []
            }
            options={options}
            isMulti
            onChange={(value) => {
              setSegmentValues({ ...segmentValues, value: JSON?.stringify(value) })
            }}
            handleSearch={(keyword) => setKeyword(keyword)}
          />
        )
      } else if (
        segmentValues?.operator === 'IS_BETWEEN' ||
        segmentValues?.operator === 'IS_NOT_BETWEEN'
      ) {
        return (
          <SearchDropdown
            placeholder="Value"
            values={
              segmentValues?.value
                ? isJson
                  ? JSON.parse(segmentValues?.value)
                  : segmentValues?.value?.split(';')
                : []
            }
            options={options}
            isMulti
            onChange={(value) => {
              setSegmentValues({ ...segmentValues, value: JSON?.stringify(value) })
            }}
            handleSearch={(keyword) => setKeyword(keyword)}
          />
        )
      } else {
        return (
          <SearchDropdown
            placeholder="Value"
            values={
              segmentValues?.value
                ? isJson
                  ? JSON.parse(segmentValues?.value)
                  : segmentValues?.value?.split(';')
                : []
            }
            options={options}
            onChange={(value) =>
              setSegmentValues({ ...segmentValues, value: JSON?.stringify(value) })
            }
            handleSearch={(keyword) => setKeyword(keyword)}
          />
        )
      }
    } else if (
      segmentValues?.operator === 'IS_BETWEEN' ||
      segmentValues?.operator === 'IS_NOT_BETWEEN'
    ) {
      return renderInput()
    } else {
      return renderInput()
    }
  }

  return (
    <div className="w-full h-full">
      <div className="flex mt-6 items-center">
        <span className="mr-2">
          <Select
            className="rounded SegmentScreenSelect"
            placeholder="Select segment"
            value={segment?.code}
            options={segments.map((segment) => ({ label: segment.name, value: segment.code }))}
            onSelect={(v) => {
              setSegment(segments.find((segment) => segment.code === v))
              setSegmentValues({
                ...segmentValues,
                type: v,
                operator: '',
                value: '',
                time_operator: '',
                time_value: '',
                count: '',
              })
            }}
          />
        </span>
        <div className="mr-2">
          <CommonInputs
            placeholder="Select operator"
            value={segmentValues?.operator?.toString() || undefined}
            options={segment?.operator.map((op) => ({ label: op.name, value: op.code }))}
            type="SINGLE_SELECT"
            onChange={(v) => {
              if (segmentValues?.time_operator?.name === 'IN_THE_LAST') {
                setSegmentValues({ ...segmentValues, time_value: '' })
              }
              setSegmentValues({ ...segmentValues, operator: v?.toString(), value: '' })
            }}
          />
        </div>
        <div className="mr-2">{renderOptionsInput()}</div>

        {segment?.time_operator?.length != 0 && (
          <div className="flex">
            <div className="mr-2">
              <CommonInputs
                placeholder="When"
                value={segmentValues?.time_operator || initialValues?.time_operator}
                options={segment?.time_operator?.map((op) => ({ label: op.name, value: op.code }))}
                type="SINGLE_SELECT"
                onChange={(v) =>
                  setSegmentValues({
                    ...segmentValues,
                    time_operator: v?.toString(),
                    time_value: '',
                  })
                }
              />
            </div>
            {segmentValues?.time_operator || segmentValues?.time_value ? (
              <div className="mr-2" style={{ width: 200 }}>
                <TimeInput
                  timeOperator={segmentValues?.time_operator || ''}
                  value={segmentValues?.time_value}
                  onChange={(v) => {
                    setSegmentValues({ ...segmentValues, time_value: v })
                  }}
                  isEditing={true}
                />
              </div>
            ) : null}
          </div>
        )}
        {segment?.extra_fields?.filter((e) => e?.code === 'count')?.length ? (
          <div>
            <span className="mr-2">count</span>

            <Input
              style={{ height: 40, borderRadius: 4, width: 100, marginRight: 8 }}
              placeholder="Value"
              value={segmentValues?.count}
              onChange={(e) => {
                setSegmentValues({ ...segmentValues, count: e.target.value.toString() })
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default SegmentScreen
