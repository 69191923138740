import React, { useState } from 'react'

const LimitInput = ({ placeholder = 'Enter text...', limit = 25, onChange, value }) => {
  const [inputValue, setInputValue] = useState(value || '')
  const [charCount, setCharCount] = useState(0)

  const handleChange = (e) => {
    const newValue = e.target?.value
    if (newValue.length <= limit) {
      setInputValue(newValue)
      setCharCount(newValue.length)
    }
  }

  return (
    <div style={{ margin: '8px 0px', maxWidth: '400px' }}>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => {
          handleChange(e)
          onChange(e)
        }}
        placeholder={placeholder}
        style={{
          width: '100%',
          padding: '8px',
          boxSizing: 'border-box',
          border: '1px solid #d9d9d9',
          fontSize: '16px',
          borderRadius: '8px',
        }}
      />
      <div style={{ marginTop: '8px', color: '#888' }}>
        {`Hint`}
        <span style={{ float: 'right' }}>
          {charCount} / {limit}
        </span>
      </div>
    </div>
  )
}

export default LimitInput
