import { Button, Divider, Drawer, Input, message, Select, Tooltip } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import React, { useEffect, useState } from 'react'
import { RiCloseLine, RiHandCoinFill, RiInformationLine, RiPencilLine } from 'react-icons/ri'
import './index.scss'
import { addTextAtPosition, formatArrayToObject, formatObjectToArray, SEGMENTS } from './constants'
import SegmentScreen from './DinamicDimensions/Customer.SegmentScreen'
import {
  getAllDimension,
  getCampaignDynamicParams,
  getDimensionDetail,
} from 'Services/campaign-dynamic-params'
import { formatValueType } from 'Pages/Audiences/Detail/helpers'
export type DynamicParamPayload = {
  param: {
    param_value: string
    param_format: string
    param_group: string
    format: any
  }
  modifier?: string
  default_value?: string
}

export default function DynamicParams({
  extraParams,
  disableAddParam,
}: {
  extraParams: any
  disableAddParam?: boolean
}) {
  const {
    content,
    selected_dynamic_param,
    audience_id,
    id,
    dynamic_param_dimensions,
    toggleDynamicParam,
    segmentation_type,
  } = useStoreState((state: any) => state.campaignModule)
  const { setState } = useStoreActions((actions: any) => actions.campaignModule)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [initialValues, setInitialValues] = useState<any>({})
  const [searchTerm, setSearchTerm] = useState('')
  const [openSelectParam, setOpenSelectParam] = useState(false)
  const [openSelectModifier, setOpenSelectModifier] = useState(false)
  const [payload, setPayload] = useState<DynamicParamPayload>({
    param: {
      param_value: '',
      param_format: '',
      param_group: '',
      format: [],
    },
    modifier: '',
    default_value: '',
  })
  const [dynamicParams, setDynamicParams] = useState<any>([])
  const [dimensions, setDimensions] = useState<any>([])
  const [dimensionDetail, setDimensionDetail] = useState<any>(null)

  const [segmentValues, setSegmentValues] = useState<any>({
    audience_Type: 'audience',
    type: '',
    value: '',
    count: '',
    operator: '',
    time_operator: '',
    time_value: '',
  })
  const handleGetCampaignDynamicParams = async () => {
    const resp = await getCampaignDynamicParams(audience_id, id)
    setDynamicParams(resp?.data)
  }

  const handleGetAllDimension = async () => {
    const resp = await getAllDimension()
    setDimensions(resp?.data)
  }

  const handleGetDimensionDetail = async (type) => {
    const resp = await getDimensionDetail(type)
    setDimensionDetail(resp?.data)
  }
  useEffect(() => {
    handleGetCampaignDynamicParams()
    handleGetAllDimension()
  }, [])

  useEffect(() => {
    if (dynamic_param_dimensions) {
      setInitialValues(JSON?.parse(dynamic_param_dimensions || '[]'))
      handleGetDimensionDetail(JSON?.parse(dynamic_param_dimensions || '[]')?.[0]?.type)
    }
  }, [dynamic_param_dimensions])
  useEffect(() => {
    if (dimensionDetail) {
      const ifExist = dynamicParams?.find(
        (item) => item?.group_code === dimensionDetail?.group_code
      )
      if (ifExist) {
        setDynamicParams(
          dynamicParams?.map((item) => {
            if (item?.group_code === dimensionDetail?.group_code) {
              return {
                ...item,
                group_condition: JSON?.parse(dynamic_param_dimensions || '[]')?.[0],
              }
            }
            return item
          })
        )
      } else {
        setDynamicParams([
          ...dynamicParams,
          {
            ...dimensionDetail,
            group_condition: JSON?.parse(dynamic_param_dimensions || '[]')?.[0],
          },
        ])
      }
    } else {
      return
    }
  }, [dimensionDetail, toggleDynamicParam, dynamic_param_dimensions])

  const clearPayload = () => {
    setPayload({
      param: {
        param_value: '',
        param_format: '',
        param_group: '',
        format: [],
      },
    })
  }

  return (
    <div className=" p-4 sticky top-0">
      <div className=" flex justify-between items-center">
        <h3 className=" text-lg font-bold">Personalize</h3>
        <RiCloseLine
          className=" cursor-pointer"
          size={20}
          onClick={() => setState({ key: 'toggleDynamicParam', value: false })}
        />
      </div>
      <div className="mt-4 mb-2 font-semibold">Parameter</div>
      <Select
        key="parameter"
        className="rounded DynamicParamsSelect"
        placeholder="Select parameter"
        style={{ width: '100%' }}
        open={openSelectParam}
        onDropdownVisibleChange={(visible) => {
          setOpenSelectParam(visible)
        }}
        onSearch={(value) => {
          setSearchTerm(value)
        }}
        allowClear
        onClear={() => {
          clearPayload()
        }}
        showSearch
        value={payload?.param?.param_value ? payload?.param?.param_value : 'Select parameter'}
        dropdownRender={(menu) => {
          return (
            <>
              <div className=" hidden">{menu}</div>
              <div className=" text-black" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                {dynamicParams?.concat(extraParams)?.map((group) => (
                  <div
                    key={group.group_name}
                    className=" border-b"
                    style={{
                      display: group?.parameters?.filter((param) =>
                        param?.parameter_code?.toLowerCase()?.includes(searchTerm?.toLowerCase())
                      ).length
                        ? 'block'
                        : 'none',
                    }}
                  >
                    <div className="flex justify-between px-4 items-center">
                      <div className="h-10 flex items-center">{group.group_name}</div>
                      <div
                        className=" gap-1 items-center"
                        style={{
                          display:
                            group?.group_condition || group?.segmentation_type ? 'flex' : 'none',
                        }}
                      >
                        <Tooltip
                          title={
                            group?.group_condition && (
                              <span>
                                {(() => {
                                  const condition = group.group_condition
                                  return [
                                    `${condition.type} ${condition.operator} ${condition.value}`,
                                    condition.time_operator?.name,
                                    condition.time_value,
                                    condition.count,
                                  ]
                                    .filter(Boolean)
                                    .join(' ')
                                })()}
                              </span>
                            )
                          }
                        >
                          <RiInformationLine />
                        </Tooltip>

                        <RiPencilLine
                          className="cursor-pointer text-blue-500"
                          onClick={() => {
                            if (group?.group_condition) {
                              setInitialValues(group?.group_condition)
                            }
                            setOpenDrawer(true)
                            setOpenSelectModifier(false)
                          }}
                        ></RiPencilLine>
                      </div>
                    </div>
                    <div className="flex gap-1 flex-col w-fit">
                      {group?.parameters
                        ?.filter((param) =>
                          param?.parameter_code?.toLowerCase()?.includes(searchTerm?.toLowerCase())
                        )
                        .map((param) => (
                          <div
                            className={`px-8 h-10 truncate w-fit flex items-center hover:bg-gray-100 text-gray-500 cursor-pointer ${
                              payload?.param?.param_value === param.parameter_code
                                ? 'bg-gray_1'
                                : ''
                            }`}
                            key={param.parameter_code}
                            onClick={() => {
                              setPayload({
                                ...payload,
                                param: {
                                  param_value: param?.parameter_code,
                                  param_format: param?.format,
                                  param_group: group?.group_code,
                                  format: param?.format,
                                },
                                modifier: '',
                                default_value: '',
                              })
                              setOpenSelectParam(false)
                            }}
                          >
                            <Tooltip title={param?.parameter_code}>
                              <span className="truncate">{param?.parameter_code}</span>
                            </Tooltip>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
              {!disableAddParam && (
                <div
                  onClick={() => setOpenDrawer(true)}
                  className="p-4 border-t cursor-pointer text-black"
                >
                  + Add Parameter
                </div>
              )}
            </>
          )
        }}
      />
      <div style={{ display: payload?.param?.param_value ? 'block' : 'none' }}>
        <div className="mt-4 mb-2 font-semibold">Modifier</div>
        <Select
          disabled={disableAddParam}
          className=" rounded DynamicParamsSelect"
          key="modifier"
          placeholder="Select modifier"
          style={{ width: '100%' }}
          onChange={(value) => {
            setPayload({
              ...payload,
              modifier: value,
            })
          }}
          value={payload?.modifier ? payload?.modifier : 'Select modifier'}
          options={payload?.param?.format?.map((format) => ({
            label: format?.format_value,
            value: format?.format_key,
          }))}
          open={openSelectModifier}
          onDropdownVisibleChange={(visible) => {
            setOpenSelectModifier(visible)
          }}
          dropdownRender={(menu) => {
            return (
              <>
                <div>
                  {payload?.param?.format?.map((format) => (
                    <div
                      className="flex items-center justify-between cursor-pointer py-2 border-b px-4 last:border-b-none"
                      onClick={() => {
                        setPayload({
                          ...payload,
                          modifier: format?.format_key,
                        })
                        setOpenSelectModifier(false)
                      }}
                    >
                      <div>{format?.format_value}</div>
                      <div className="text-gray-500">{format?.description}</div>
                    </div>
                  ))}
                </div>
              </>
            )
          }}
        />
      </div>
      <div style={{ display: payload?.param?.param_value ? 'block' : 'none' }}>
        <div className="mt-4 mb-2 font-semibold">Default value</div>
        <Input
          disabled={disableAddParam}
          className="py-2 rounded"
          placeholder={'Eg: Anh/Chị or 1000, ...'}
          value={payload?.default_value}
          onChange={(e) => {
            setPayload({
              ...payload,
              default_value: e.target.value,
            })
          }}
        />
      </div>
      <Button
        className="w-full mt-4 border border-solid border-gray_5 rounded !text-black !font-semibold"
        onClick={() => {
          if (!payload?.param?.param_value) {
            message.error('Please select parameter', 2)
          } else {
            if (!selected_dynamic_param?.ZNSKey) {
              setState({
                key: 'content',
                value: {
                  ...content,
                  [selected_dynamic_param?.key]: addTextAtPosition(
                    content?.[selected_dynamic_param?.key] || selected_dynamic_param?.value || '',
                    selected_dynamic_param?.position,
                    `{{${
                      payload?.param?.param_group ===
                        JSON?.parse(dynamic_param_dimensions || '[]')?.[0]?.type ||
                      payload?.param?.param_group ===
                        JSON?.parse(dynamic_param_dimensions || '[]')?.type
                        ? payload?.param?.param_group + '_'
                        : ''
                    }${payload.param?.param_value}|${
                      payload.modifier ? `${payload.modifier}` : ''
                    }|${payload.default_value ? `${payload.default_value}` : ''}}}`
                  )?.trim(),
                },
              })
            } else {
              if (selected_dynamic_param?.ZNSSource === 'cj') {
                const templateDataArray = formatObjectToArray(content?.template_data || {})
                const existingItemIndex = templateDataArray.findIndex(
                  (item) => item.key === selected_dynamic_param?.ZNSKey
                )
                if (existingItemIndex !== -1) {
                  templateDataArray[existingItemIndex] = {
                    key: selected_dynamic_param?.ZNSKey,
                    name: addTextAtPosition(
                      templateDataArray[existingItemIndex]?.name,
                      selected_dynamic_param?.position,
                      `{{${
                        payload?.param?.param_group ===
                        JSON?.parse(dynamic_param_dimensions || '[]')?.[0]?.type
                          ? payload?.param?.param_group + '_'
                          : ''
                      }${payload.param?.param_value}|${
                        payload.modifier ? `${payload.modifier}` : ''
                      }|${payload.default_value ? `${payload.default_value}` : ''}}}`
                    )?.trim(),
                  }
                } else {
                  templateDataArray.push({
                    key: selected_dynamic_param?.ZNSKey,
                    name: addTextAtPosition(
                      selected_dynamic_param?.ZNSValue,
                      selected_dynamic_param?.position,
                      `{{${
                        payload?.param?.param_group ===
                          JSON?.parse(dynamic_param_dimensions || '[]')?.[0]?.type ||
                        payload?.param?.param_group ===
                          JSON?.parse(dynamic_param_dimensions || '[]')?.type
                          ? payload?.param?.param_group + '_'
                          : ''
                      }${payload.param?.param_value}|${
                        payload.modifier ? `${payload.modifier}` : ''
                      }|${payload.default_value ? `${payload.default_value}` : ''}}}`
                    )?.trim(),
                  })
                }
                setState({
                  key: 'content',
                  value: { ...content, template_data: formatArrayToObject(templateDataArray) },
                })
              } else {
                if (
                  content?.params?.filter((item) => item.name === selected_dynamic_param?.ZNSKey)
                    ?.length
                ) {
                  const filterContent = content?.params?.filter(
                    (i) => i.name !== selected_dynamic_param?.ZNSKey
                  )
                  const params = [
                    ...filterContent,
                    {
                      key: addTextAtPosition(
                        content?.params?.filter(
                          (item) => item.name === selected_dynamic_param?.ZNSKey
                        )?.[0]?.key,
                        selected_dynamic_param?.position,
                        `{{${
                          payload?.param?.param_group ===
                            JSON?.parse(dynamic_param_dimensions || '[]')?.[0]?.type ||
                          payload?.param?.param_group ===
                            JSON?.parse(dynamic_param_dimensions || '[]')?.type
                            ? payload?.param?.param_group + '_'
                            : ''
                        }${payload.param?.param_value}|${
                          payload.modifier ? `${payload.modifier}` : ''
                        }|${payload.default_value ? `${payload.default_value}` : ''}}}`
                      )?.trim(),
                      name: selected_dynamic_param?.ZNSKey,
                    },
                  ]
                  setState({ key: 'content', value: { ...content, params } })
                } else {
                  const params = [
                    ...content?.params,
                    {
                      key: addTextAtPosition(
                        content?.params?.filter(
                          (item) => item.name === selected_dynamic_param?.ZNSKey
                        )?.[0]?.key,
                        selected_dynamic_param?.position,
                        `{{${
                          payload?.param?.param_group ===
                            JSON?.parse(dynamic_param_dimensions || '[]')?.[0]?.type ||
                          payload?.param?.param_group ===
                            JSON?.parse(dynamic_param_dimensions || '[]')?.type
                            ? payload?.param?.param_group + '_'
                            : ''
                        }${payload.param?.param_value}|${
                          payload.modifier ? `${payload.modifier}` : ''
                        }|${payload.default_value ? `${payload.default_value}` : ''}}}`
                      )?.trim(),
                      name: selected_dynamic_param?.ZNSKey,
                    },
                  ]
                  setState({ key: 'content', value: { ...content, params } })
                }
              }
            }
          }
        }}
      >
        <p className=" font-semibold">Insert</p>
      </Button>
      <Drawer
        visible={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
          handleGetDimensionDetail(segmentValues?.type)
        }}
        title="Add new parameter"
        width={980}
        className="DynamicParamsDrawer"
        footer={
          <Button
            className=" border text-right border-solid border-gray_5 rounded !text-black !font-semibold"
            onClick={() => {
              handleGetDimensionDetail(segmentValues?.type)
              setOpenDrawer(false)
              setState({
                key: 'dynamic_param_dimensions',
                value: JSON?.stringify([
                  {
                    ...segmentValues,
                    segmentation_type: segmentation_type || null,
                    value: segmentValues?.value,
                    time_value: formatValueType(segmentValues.time_value),
                  },
                ]),
              })
              console.log('segmentValues', segmentValues)
              message.success('Add new parameter successfully', 2)
            }}
          >
            Add
          </Button>
        }
      >
        <div>
          <SegmentScreen
            segments={dimensions}
            initialValues={initialValues}
            segmentValues={segmentValues}
            setSegmentValues={setSegmentValues}
            isEditing={true}
          />
        </div>
      </Drawer>
    </div>
  )
}
