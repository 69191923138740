import usePromotionDetail from 'Components/CJPopover/NodeConfigs/PromotionNode/usePromotionDetail'
import React, { useEffect } from 'react'

export default function ProductLabel({
  promotionName,
  promotionId,
  onChange,
  content,
}: {
  promotionName: string
  promotionId: string
  onChange: (name: string, value: any) => void
  content: any
}) {
  const { promotion: promotionData } = usePromotionDetail(promotionId)
  useEffect(() => {
    if (promotionId) {
      onChange(promotionName, { ...content[promotionName], product: promotionData?.variants?.[0] })
    }
  }, [promotionData])
  return (
    <div>
      <input
        disabled
        type="text"
        value={promotionData?.variants?.[0]?.name}
        style={{
          width: '100%',
          padding: '8px',
          boxSizing: 'border-box',
          border: '1px solid #d9d9d9',
          fontSize: '16px',
          borderRadius: '8px',
        }}
      />
    </div>
  )
}
