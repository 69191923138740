import React, { useState, useEffect } from 'react'
import { Select, Spin } from 'antd'
import axios from 'Utils/axios'

const { Option } = Select

const ProductSelect = ({ onChange, disabled }) => {
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  // Function to load products from the API
  const loadProducts = async (searchTerm = '', page = 1) => {
    setLoading(true)
    try {
      const response = await axios.get(`/products/onelife/search`, {
        params: { page, name: searchTerm, limit: 20, order: 'created_at', direction: 'desc' },
      })
      const newProducts = response.data.variants

      setProducts((prev) => (page === 1 ? newProducts : [...prev, ...newProducts]))
      setHasMore(newProducts.length > 0)
    } catch (error) {
      console.error('Error loading products:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadProducts(searchTerm, page)
  }, [searchTerm, page])

  const handleSearch = (value) => {
    setSearchTerm(value)
    setPage(1) // Reset page when searching
  }

  const handlePopupScroll = (event) => {
    const { target } = event
    if (target.scrollTop + target.offsetHeight === target.scrollHeight && hasMore) {
      setPage((prevPage) => prevPage + 1) // Load more products on scroll
    }
  }
  if (disabled) return null
  return (
    <Select
      showSearch
      placeholder="Select a product"
      filterOption={false} // Disable built-in filtering, use custom search instead
      onSearch={handleSearch}
      onPopupScroll={handlePopupScroll}
      notFoundContent={loading ? <Spin size="small" /> : null}
      onChange={onChange}
    >
      {products?.map((product: any) => (
        <Option key={product?.id} value={product?.sku}>
          <div className="flex items-center gap-2">
            <img src={product?.image_url} alt={product?.name} className="w-8 h-8" />
            <div className="flex flex-col">
              <div className="text-sm font-semibold">{product?.name}</div>
              <div className="text-xs text-gray-500">{product?.sku}</div>
            </div>
          </div>
        </Option>
      ))}
    </Select>
  )
}

export default ProductSelect
