import useDebounce from 'Hooks/useDebounce'
import axios from 'Utils/axios'
import { useStoreActions, useStoreState } from 'easy-peasy'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export default function useZaloGroup() {
  const { id } = useParams()
  const setCampaignState = useStoreActions((action: any) => action.campaignModule.setState)
  const { zalo_groups } = useStoreState((action: any) => action.campaignModule)
  const [groups, setGroups] = useState<any[]>([])
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined)

  const changeKeyValue = (list, keyNeedChange, keyToGet) => {
    if (list[keyToGet] === '') {
      return null
    } else if (list[keyToGet]) {
      console.log(list)
      let temp = list[keyToGet]
      list.storeKey = list.key
      list[keyNeedChange] = temp
      return list
    }

    return {
      ...list,
      children: list.children
        ?.map((item) => {
          return changeKeyValue(item, keyNeedChange, keyToGet)
        })
        ?.filter((item) => item),
    }
  }
  const fetchZaloGroups = async (search?: string) => {
    const resp = await axios.get('/stores/', {
      params: {
        company: 'kingfood',
        keyword: search,
        is_zalo_group: true,
      },
    })
    const changedKeyData = resp.data.tree_store.map((region) =>
      changeKeyValue(region, 'key', 'zalo_group_code')
    )
    console.log(changedKeyData)
    setGroups(changedKeyData)
    // setGroups([])
  }

  const fetchCampaignZaloGroups = async (id?: string) => {
    const resp = await axios.get(`/campaigns/${id}/zalo-groups`)
    setCampaignState({
      key: 'zalo_groups',
      value: resp.data.zalo_groups.map((item) => item.code_name),
    })
  }

  const debounceSearchQuery = useDebounce(searchQuery, 500)

  useEffect(() => {
    fetchZaloGroups()
  }, [])

  useEffect(() => {
    console.log(id)
    if (id) {
      fetchCampaignZaloGroups(id)
    }
  }, [id])

  useEffect(() => {
    if (debounceSearchQuery !== undefined) fetchZaloGroups(debounceSearchQuery)
  }, [debounceSearchQuery])

  const onChangeZaloGroups = (v: string[]) => {
    setCampaignState({ key: 'zalo_groups', value: v })
  }

  return {
    searchQuery,
    setSearchQuery,
    groups,
    setGroups,
    values: zalo_groups,
    onChangeZaloGroups,
  }
}
